import { j as c } from "./jsx-runtime-B6kdoens.js";
import * as V from "react";
import { useMemo as z, useCallback as X, forwardRef as q, useState as w } from "react";
import { _ as J } from "./index-28E3tTLh.js";
import { _ as K, a as T, b as L, v as Q, d as A, g as U, e as Y, u as Z, S as ee, c as h } from "./Select-49a62830.esm-BCGPtZ9W.js";
import "react-dom";
import "./Accordion-Bzgs65lf.js";
import "./Avatar-95vFNu6x.js";
import { B as te } from "./Badge-C0bj4DFU.js";
import "./Button-ChOUYHf0.js";
import "./DropdownMenu-Hxa3M2LS.js";
import { I as oe } from "./Icon-CK-T4e-h.js";
import "./Input-1P9Hc4eP.js";
import "./Label-CI8_Vg74.js";
import "./Text-RuGJaJiA.js";
import { T as ne } from "./Tooltip-Dy7rqQaX.js";
import { c as B } from "./utils-CJ9afRe1.js";
import "./Stack-C1p7gIIp.js";
import "./Switch-BgHWRaX7.js";
import "./Toast-DkQ3kscR.js";
import "./Toggle-Cn0qt44_.js";
var ae = ["allowCreateWhileLoading", "createOptionPosition", "formatCreateLabel", "isValidNewOption", "getNewOptionData", "onCreateOption", "options", "onChange"], F = function() {
  var o = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "", a = arguments.length > 1 ? arguments[1] : void 0, i = arguments.length > 2 ? arguments[2] : void 0, s = String(o).toLowerCase(), r = String(i.getOptionValue(a)).toLowerCase(), d = String(i.getOptionLabel(a)).toLowerCase();
  return r === s || d === s;
}, N = {
  formatCreateLabel: function(o) {
    return 'Create "'.concat(o, '"');
  },
  isValidNewOption: function(o, a, i, s) {
    return !(!o || a.some(function(r) {
      return F(o, r, s);
    }) || i.some(function(r) {
      return F(o, r, s);
    }));
  },
  getNewOptionData: function(o, a) {
    return {
      label: a,
      value: o,
      __isNew__: !0
    };
  }
};
function re(t) {
  var o = t.allowCreateWhileLoading, a = o === void 0 ? !1 : o, i = t.createOptionPosition, s = i === void 0 ? "last" : i, r = t.formatCreateLabel, d = r === void 0 ? N.formatCreateLabel : r, v = t.isValidNewOption, b = v === void 0 ? N.isValidNewOption : v, x = t.getNewOptionData, e = x === void 0 ? N.getNewOptionData : x, n = t.onCreateOption, m = t.options, l = m === void 0 ? [] : m, O = t.onChange, p = K(t, ae), j = p.getOptionValue, I = j === void 0 ? U : j, M = p.getOptionLabel, _ = M === void 0 ? Y : M, u = p.inputValue, W = p.isLoading, E = p.isMulti, C = p.value, k = p.name, g = z(function() {
    return b(u, T(C), l, {
      getOptionValue: I,
      getOptionLabel: _
    }) ? e(u, d(u)) : void 0;
  }, [d, e, _, I, u, b, l, C]), H = z(function() {
    return (a || !W) && g ? s === "first" ? [g].concat(L(l)) : [].concat(L(l), [g]) : l;
  }, [a, s, W, g, l]), R = X(function(f, y) {
    if (y.action !== "select-option")
      return O(f, y);
    var D = Array.isArray(f) ? f : [f];
    if (D[D.length - 1] === g) {
      if (n) n(u);
      else {
        var S = e(u, u), G = {
          action: "create-option",
          name: k,
          option: S
        };
        O(Q(E, [].concat(L(T(C)), [S]), S), G);
      }
      return;
    }
    O(f, y);
  }, [e, u, E, k, g, n, O, C]);
  return A(A({}, p), {}, {
    options: H,
    onChange: R
  });
}
var ie = /* @__PURE__ */ q(function(t, o) {
  var a = Z(t), i = re(a);
  return /* @__PURE__ */ V.createElement(ee, J({
    ref: o
  }, i));
}), se = ie;
const le = () => null, ce = (t) => /* @__PURE__ */ c.jsxs(h.Option, { ...t, children: [
  t.children,
  " ",
  t.data.private && /* @__PURE__ */ c.jsx(oe, { name: "Lock", width: 16, height: 16 })
] }), pe = (t) => /* @__PURE__ */ c.jsx(h.Menu, { ...t, children: t.children }), ue = (t) => /* @__PURE__ */ c.jsx(h.MenuList, { ...t, children: t.children }), de = (t) => /* @__PURE__ */ c.jsx(h.MenuPortal, { ...t, "data-toast": "true", children: t.children }), me = (t) => {
  const o = t.getValue().length;
  return /* @__PURE__ */ c.jsxs(h.ValueContainer, { ...t, children: [
    t.children,
    o > 1 && /* @__PURE__ */ c.jsxs(
      te,
      {
        variant: "secondary",
        className: "py-0.5 h-5 group-focus-within/select:hidden mt-1 border border-gray-neutral-80",
        children: [
          "+",
          o - 1,
          " more"
        ]
      }
    )
  ] });
}, P = {
  base: "border-0  rounded-none bg-white hover:cursor-text text-sm",
  focus: "",
  nonFocus: ""
}, ge = "text-gray-500 pl-1 group-focus-within/select:py-0.5", fe = "pl-1 group-focus-within/select:py-0.5 group-focus-within/select:flex", he = "p-1 gap-1 text-sm group-focus-within/select:pt-1 group-focus-within/select:flex-wrap", ve = "leading-7 ml-1", be = "p-1 gap-1 items-start flex flex-row", xe = "hidden w-0 overflow-hidden", Oe = "w-0", Ce = "p-1 hover:bg-gray-100 text-black-primary rounded-md cursor-pointer", ye = "mt-2 border-gray-neutral-80 shadow border bg-white rounded-lg z-dialog max-w-md", Se = "text-black-primary text-sm font-medium", $ = {
  base: "text-sm truncate overflow-hidden cursor-pointer rounded-md p-2 shrink-0 flex flex-row gap-2 items-center",
  focus: "bg-gray-neutral-30 active:bg-gray-200",
  selected: "text-gray-500"
}, we = "group/select", Le = "pl-2 flex flex-col gap-3 text-sm [&>div]:flex [&>div]:flex-col [&>div]:gap-2 [&>div:last-of-type]:pl-2", Ne = "p-2 flex flex-col gap-2 scrollbar-stable overflow-hidden max-h-56", qe = (t) => {
  const [o, a] = w(
    t.options
  ), [i, s] = w(t.value), [r, d] = w(""), v = () => {
    const e = (r == null ? void 0 : r.trim()) || "", n = o.find((l) => l.label === e);
    if (!e || n)
      return;
    const m = {
      label: e,
      value: e,
      type: "NEW",
      private: !1
    };
    a((l) => [...l, m]), s(m), t.onChange(m);
  }, b = (e, { action: n }) => {
    n === "input-change" && d(e), n === "input-blur" && v();
  }, x = (e) => {
    const n = {
      label: (e == null ? void 0 : e.label) ?? "",
      value: (e == null ? void 0 : e.value) ?? "",
      type: (e == null ? void 0 : e.type) ?? "EXISTING",
      private: (e == null ? void 0 : e.private) ?? !1
    };
    s(n), d(n.label), t.onChange(n);
  };
  return /* @__PURE__ */ c.jsx(ne.Provider, { children: /* @__PURE__ */ c.jsx(
    se,
    {
      isValidNewOption: () => !1,
      onInputChange: b,
      isClearable: !0,
      closeMenuOnSelect: !0,
      noOptionsMessage: () => null,
      hideSelectedOptions: !1,
      unstyled: !0,
      styles: {
        container: (e) => ({
          ...e,
          width: "100%",
          overflow: "hidden"
        }),
        input: (e) => ({
          ...e,
          "input:focus": {
            boxShadow: "none"
          }
        }),
        valueContainer: (e) => ({
          ...e,
          flexWrap: "nowrap"
        }),
        menu: (e) => ({
          ...e,
          zIndex: 1304
        }),
        menuList: (e) => ({
          ...e,
          maxHeight: void 0
        }),
        option: (e) => ({
          ...e,
          display: "flex",
          fontSize: "14px",
          cursor: "pointer"
        }),
        menuPortal: (e) => ({
          ...e,
          zIndex: 1304,
          pointerEvents: "auto"
        }),
        control: (e) => ({
          ...e,
          transition: "none",
          borderWidth: 0,
          boxShadow: "none"
        })
      },
      components: {
        Option: ce,
        Menu: pe,
        MenuList: ue,
        DropdownIndicator: le,
        ValueContainer: me,
        MenuPortal: de
      },
      classNames: {
        control: ({ isFocused: e }) => B(
          e ? P.focus : P.nonFocus,
          P.base
        ),
        placeholder: () => ge,
        input: () => fe,
        container: () => we,
        valueContainer: () => he,
        singleValue: () => ve,
        indicatorsContainer: () => be,
        clearIndicator: () => xe,
        indicatorSeparator: () => Oe,
        dropdownIndicator: () => Ce,
        menu: () => ye,
        groupHeading: () => Se,
        group: () => Le,
        menuList: () => Ne,
        option: ({ isFocused: e, isSelected: n }) => B(
          e && $.focus,
          n && $.selected,
          $.base
        )
      },
      ...t,
      className: "z-dialog",
      options: o,
      value: i,
      onChange: x,
      menuPlacement: "auto",
      menuPosition: "fixed",
      menuPortalTarget: document.body,
      openMenuOnClick: !1,
      openMenuOnFocus: !1,
      tabSelectsValue: !1,
      filterOption: (e) => e.data.type !== "NEW" && e.data.label.toLowerCase().includes(r.toLowerCase())
    }
  ) });
};
export {
  qe as default
};
